<template>
  <div style="width: 100%;">
    <v-row style="margin-left: -30px; margin-top: -20px; padding-top: 0px; position:fixed; z-index:100; background-color: LightGrey;">
      <v-col class='d-flex flex-row align-center justify-start;'>
        <v-btn class="mr-2" fab x-small color="info" @click="()=>this.$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <span>
          <h2>Order #{{this.$route.params.id}}</h2>
          <div v-if="this.invoice && this.invoice.createdBy" style="margin-top: -5px; font-size: 10px;"> Created By: {{this.lookupUsername(this.invoice.createdBy)}}</div>
        </span>
        <v-icon v-if="this.syncStatus===0" class="ml-2" color="warning">mdi-cloud-refresh</v-icon>
        <v-icon v-if="this.syncStatus===1" class="ml-2" color="success">mdi-cloud-check</v-icon>
        <v-icon v-if="this.syncStatus===2" class="ml-2" color="error">mdi-cloud-alert</v-icon>
        <v-progress-circular
          indeterminate
          color="green"
          v-if="loader"
        ></v-progress-circular>
        <v-progress-circular
          indeterminate
          color="green"
          v-if="initialLoader"
        ></v-progress-circular>
        <span class="pl-2" v-if="!loader">
          <span v-if="invoice.status===-1" class="pa-1 v-btn warning" style="font-size: 10px;">{{parseOrderStatus(invoice.status)}}</span>
          <span v-if="invoice.status===100" class="pa-1 v-btn red" style="font-size: 10px;">Draft (Unsaved)</span>
          <span v-if="invoice.status===0" class="pa-1 v-btn orange" style="font-size: 10px;">{{parseOrderStatus(invoice.status)}}</span>
          <span v-if="invoice.status===1" class="pa-1 v-btn success" style="font-size: 10px;">{{parseOrderStatus(invoice.status)}}</span>
          <span v-if="invoice.status===2" class="pa-1 v-btn success" style="font-size: 10px;">{{parseOrderStatus(invoice.status)}}</span>
          <span v-if="invoice.status===3" class="pa-1 v-btn success" style="font-size: 10px;">{{parseOrderStatus(invoice.status)}}</span>
          <span v-if="invoice.status===4" class="pa-1 v-btn success" style="font-size: 10px;">{{parseOrderStatus(invoice.status)}}</span>
          <span v-if="invoice.status===5" class="pa-1 v-btn success" style="font-size: 10px;">{{parseOrderStatus(invoice.status)}}</span>
          <span v-if="invoice.status===6" class="pa-1 v-btn success" style="font-size: 10px;">{{parseOrderStatus(invoice.status)}}</span>
        </span>  
        <v-btn :disabled="this.invoice.status<1" class="ml-2" fab x-small color="info" @click="openPrintDialog()"><v-icon>mdi-printer</v-icon></v-btn>
        <v-btn :disabled="this.invoice.status<1" class="ml-2" fab x-small color="info" @click="printPreview()"><v-icon>mdi-printer-eye</v-icon></v-btn>
      </v-col>
      <span class="d-flex flex-row flex-wrap align-center justify-center pa-2" style="margin-top: -20px; margin-left:35px;">
        <authorizer
          v-if="isAllowed('order', 'seal')" 
          v-bind:buttontext="'Seal'" 
          :disabled="!this.invoiceOK() || this.invoice.status !== 0" 
          v-on:response="authAttempt($event,'seal')"
        />
        <authorizer 
          v-if="isAllowed('order', 'voidInvoice')" 
          v-bind:buttontext="'Void'" 
          :disabled="this.invoice.status===-1 || this.invoice.status > 5" 
          v-bind:fabicon="null" v-bind:color="'warning'" 
          v-on:response="authAttempt($event,'void')"
        />
      </span>
    </v-row>
    <v-row style="margin-top: 90px;">
      <v-col>
        <v-card outlined class="pa-2">
          <span class="mr-3 d-flex flex-row">
            <h3 style="text-align: left;">Customer</h3>
            <v-btn :disabled="this.modifyDisabledCriteria" class="ml-2" v-if="!this.modifyDisabledCriteria" @click="createCustomerDialog=true" fab color="info" x-small><v-icon>mdi-account-plus</v-icon></v-btn>
            <v-btn :disabled="this.modifyDisabledCriteria" v-if="!this.modifyDisabledCriteria" @click="customersDialog=true" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-account-search</v-icon></v-btn>
          </span>
          <span v-if="invoice.customerId && selectedCustomer" class="d-flex flex-row justify-start align-center">
            <span class="d-flex flex-column mr-2">
              <h4 style="text-align: left;">{{selectedCustomer.name}}</h4>
              <span v-if="selectedCustomer.phone"><b>Phone: </b>{{selectedCustomer.phone}}</span>
            </span>
            <v-btn :disabled="this.modifyDisabledCriteria" @click="removeCustomerFromInvoice()" fab x-small color="error"><v-icon>mdi-close</v-icon></v-btn>
          </span>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-column">
        <v-card outlined class="pa-2">
          <h3>Payment Method</h3>
          <span>
            <v-radio-group dense class="mt-0" :disabled="this.modifyDisabledCriteria" v-model="invoice.metadata.paymentMethod" @change="this.updateInvoice">
              <v-radio
                  v-for="x in this.paymentMethods"
                  :key="x.id"
                  :label="x.name"
                  :value="x.id"
              ></v-radio>
            </v-radio-group>
            <v-text-field
              outlined
              dense
              type="number"
              v-if="invoice.metadata.paymentMethod===2"
              name="name"
              label="HP Dowpayment"
              @change="updateInvoice()"
              v-model="invoice.metadata.hpDownpayment"
            ></v-text-field>
          </span>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="invoice.customerId">
        <v-card outlined class="pa-2">
          <v-row>
            <v-col class="d-flex flex-row">
              <h3>Delivery Information</h3>
              <v-btn class="ml-3" v-if="!this.showDeliveryInfo" @click="showDeliveryInfo=true" x-small fab color="success"><v-icon>mdi-chevron-down</v-icon></v-btn>
              <v-btn class="ml-3" v-else x-small fab color="error" @click="showDeliveryInfo=false"><v-icon>mdi-chevron-up</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row style="margin-top:-40px;" v-if="this.showDeliveryInfo">
            <v-col class="d-flex flex-column">
              <span>
                <v-radio-group dense :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.deliveryType" @change="this.updateInvoice" row>
                  <v-radio
                      label="Delivery"
                      value="delivery"
                  ></v-radio>
                  <v-radio
                      label="Pick Up"
                      value="pickup"
                  ></v-radio>
                </v-radio-group>
              </span>
              <span v-if="this.invoice.deliveryInfo.deliveryType == 'delivery'">
                <span class="d-flex flex-column">
                  <span class="d-flex flex-row justify-center mb-1">
                    <h3>Delivery Address</h3>
                    <v-btn :disabled="this.modifyDisabledCriteria" v-if="selectedCustomer" color="info" class="ml-2" x-small fab @click="addressDialog=true"><v-icon>mdi-chevron-right</v-icon></v-btn>
                  </span>
                  <span class="d-flex flex-row">
                    <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.line1" @change="checkCustomAddress" label="Address Line 1" outlined></v-text-field>
                    <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.line2" @change="checkCustomAddress" label="Address Line 2" outlined></v-text-field>
                  </span>
                  <span class="d-flex flex-row">
                    <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.city" @change="checkCustomAddress" label="Town/City" outlined></v-text-field>
                    <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.country" @change="checkCustomAddress" label="Country" outlined></v-text-field>
                  </span>
                  <v-btn style="margin-top: -20px;" v-if="invoice.deliveryInfo.customAddress" color="info" @click="saveCustomAddressDialog=true" small>Save Custom Address to Customer</v-btn>
                  <h3 class="mt-2">Delivery Charges</h3>
                  <v-text-field :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.cost" min="0" @change="this.updateInvoice" label="Delivery Cost" type="number" outlined></v-text-field>
                </span>
              </span>
            </v-col>
          </v-row>
        </v-card>
        <!-- <v-card class="pa-2" outlined v-if="!initialLoader && invoice.status>=1 && invoice.deliveryInfo.deliveryType==='delivery'">
          <span class="d-flex flex-row align-center">
            <h3>Delivery Schedule</h3>
            <v-btn :disabled="invoice.status < 1" x-small fab class="ml-3" color="info" @click="createDeliveryA()"><v-icon>mdi-plus</v-icon></v-btn>
          </span>
          <div v-for="item,i in deliverySchedule.data" :key="i">
            <div class="d-flex flex-row justify-space-between align-center" style="width: 100%; align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
              <div>ID: {{item.id}} | ITEMS: {{item.deliveryItems?item.deliveryItems.length:0}} | {{item.dueDate}}</div>
              <v-btn class="ml-2" x-small fab color="success" :to="'/deliveries/view/'+item.id"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
          </div>
        </v-card> -->
      </v-col>
    </v-row>
    <v-row>    
      <v-col>
        <v-card outlined class="pa-2">
          <h3 class="mb-2">Order Items ({{invoice.OrderLineItems?invoice.OrderLineItems.reduce((acc,x)=>acc+parseInt(x.quantity),0):0}} units)</h3>
          <v-card outlined :key="i" v-for="(product, i) in invoice.OrderLineItems" class="pa-2 mb-2 d-flex flex-row align-center justify-space-between">
            <span class="d-flex flex-column justify-start" style="text-align: left;">
              <!-- <p>{{product}}</p> -->
              <h4>{{product.productName}}</h4>
              <p><b>Brand: </b>{{product.Product.Brand?product.Product.Brand.name:'-'}} | <b>SKU: </b>{{product.sku?product.sku:"-"}} | <b>ID:</b> {{product.productId}}</p>
              <span style="margin-top:-10px;" class="d-flex flex-row justify-center align-center">
                <v-text-field :disabled="modifyDisabledCriteria" type="number" min="1" dense v-model="product.quantity" @change="updateLineItem(product)" :max="product.max" style="width: 60px; margin-bottom: -27px;" outlined/>
                <span class="ml-2" v-if="product.unitPrice">x<v-chip class="ma-2" @click="openChangeUnitPriceType(product)" :color="product.metadata?product.metadata.unitPriceType?product.metadata.unitPriceType==1?'green':'red':'red':'red'" label>${{product.unitPrice}}</v-chip></span>
                <span v-if="product.metadata.lineTotal">=${{product.metadata.lineTotal}}</span>
              </span>
            </span>
            <v-btn :disabled="modifyDisabledCriteria" class="mr-2" x-small fab color="error" @click="removeProduct(product)"><v-icon>mdi-close</v-icon></v-btn>
          </v-card>
          <div id="subtotals" class="d-flex flex-row justify-end" style="width: 100%; margin-top: 10px;">
            <div class="d-flex flex-column align-end">
              <b>Subtotal</b>
            </div>
            <div style="width: 120px; padding-right:20px;" class="d-flex flex-column align-end">
              <span class="d-flex flex-column align-end">
                <div>{{this.formatPrice(this.invoice.subtotal)}}</div>
              </span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!initialLoader && !this.modifyDisabledCriteria">
      <v-col>
        <span class="d-flex flex-column">
          <v-card outlined class="pa-2">
            <span class="d-flex flex-row align-center">
              <h3>Add Product</h3>
            </span>
            <span class="d-flex flex-row align-center justify-center">
              <v-text-field :disabled="this.modifyDisabledCriteria" v-model="productSearchTerm" @change="searchProduct()" :loading="loadingSearchProduct" label="Search Product" dense outlined></v-text-field>
              <v-btn @click="searchProduct()" class="mb-6 ml-3" fab x-small color="success">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </span>
            <p v-if="noSearchResults">No search results were found.</p>
            <span v-if="productSearchResults&&productSearchResults.length>0">
              <span class="d-flex flex-row justify-center align-center">
                <b>Found {{productSearchResults.length}} Results for <q>{{productSearchTerm}}</q></b>
                <v-btn class="mb-2" x-small fab color="error" style="margin-left: 10px;" @click="clearProductSearchResults"><v-icon>mdi-close</v-icon></v-btn>
              </span>
              <v-card outlined :key="i" v-for="(product, i) in productSearchResults" class="mb-2 d-flex flex-row align-center justify-space-between">
                <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
                  <h4>{{product.name}}</h4>
                  <b style="font-size:12px;" v-if="product.Brand" >Brand: {{product.Brand.name}}</b>
                  <span style="font-size:12px;">SKU: {{product.sku?product.sku:'-'}} | ID: PL-{{product.id}}</span>
                  <span>Reg: ${{product.regularPrice?product.regularPrice:'-'}} | Sale: ${{product.salePrice?product.salePrice:'-'}}</span>
                  <span v-if="product.Suppliers && product.Suppliers.length > 0 && product.Suppliers[0].ProductSupplierJoin && product.Suppliers[0].ProductSupplierJoin.costPrice">Cost Price ${{product.Suppliers[0].ProductSupplierJoin.costPrice}} (+{{utils.calculateMarkup(product.Suppliers[0].ProductSupplierJoin.costPrice,product.regularPrice)}})</span>
                  <span><b>Qty: {{product.ProductLocationJoins.find(x => x.userBranch===true)?product.ProductLocationJoins.find(x => x.userBranch===true).available:0}}</b> [{{product.ProductLocationJoins.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span>
                </span>
                <v-btn class="mr-3" :disabled="(product.ProductLocationJoins.find(x => x.userBranch===true)?product.ProductLocationJoins.find(x => x.userBranch===true).available:0)<1" x-small fab color="success" @click="addProductToInvoice(product)"><v-icon>mdi-plus</v-icon></v-btn>
              </v-card>
            </span>
          </v-card>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-column">
        <v-card v-if="invoice.OrderLineItems && invoice.OrderLineItems.length>0" outlined class="flex-grow-1 pa-2">
          <h3>Discount on Subtotal</h3>
            <v-text-field class="mt-2" dense :disabled="this.modifyDisabledCriteria"  min="0" type="number" v-model="invoice.discountAmount" @change="checkDiscount()" label="Discount Amount ($TTD)" outlined/>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex">
        <v-card v-if="invoice&&invoice.metadata.grandTotal" outlined class="flex-grow-1 pa-2 mb-5">
          <h3>Verify Totals</h3>
          <span class="d-flex flex-row justify-end">
            <span class="d-flex flex-column text-right pr-2">
              <b>Subtotal:</b>
              <b>-Discounts:</b>
              <b v-if="this.invoice.metadata.creditNotes&&this.invoice.metadata.creditNotes.length>0">-Credit Notes:</b>
              <b>+Shipping:</b>
              <h3>Grand Total:</h3>
            </span>
            <span class="d-flex flex-column text-left">
              <span>{{formatPrice(this.invoice.subtotal)}}</span>
              <span>{{formatPrice(this.invoice.discountAmount)}}</span>
              <span v-if="this.invoice.metadata.creditNotes&&this.invoice.metadata.creditNotes.length>0">{{formatPrice(invoice.metadata.creditNotesTotal)}}</span>
              <span>{{formatPrice(this.invoice.deliveryInfo.cost ? this.invoice.deliveryInfo.cost : 0)}}</span>
              <h3>{{formatPrice(this.invoice.metadata.grandTotal)}}</h3>
            </span>
          </span>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <authorizer
          v-bind:buttontext="'Seal'" 
          v-bind:size="'small'"
          :disabled="!this.invoiceOK() || this.invoice.status !== 0" 
          v-on:response="authAttempt($event,'seal')"
        />  
        <authorizer
          v-bind:buttontext="'Void'" 
          v-bind:size="'small'"
          :disabled="this.invoice.status===-1 || this.invoice.status > 5" 
          style="margin-bottom: 10px;" v-bind:fabicon="null" v-bind:color="'warning'" 
          v-on:response="authAttempt($event,'void')"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="printPreviewDialog.isOpen"
      scrollable
    >
      <v-card v-if="printPreviewDialog.data">
        <v-card-title class="text-h5">Preview</v-card-title>
        <v-card-text>
          <span v-html="printPreviewDialog.data.data"></span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintPreviewDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createCustomerDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Customer
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field :rules="nameRules" v-model="newCustomer.name" label="Name" outlined></v-text-field>
          <v-text-field v-model="newCustomer.phone" label="Phone" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!newCustomer.name" class="primary" text @click="createCustomer">
            Create
            <v-progress-circular
                indeterminate
                color="green"
                v-if="customerLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
          <v-btn class="error" text @click="closeCreateCustomer">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="customersDialog" width="500">
      <v-card outlined>
        <v-card-title class="text-h5">
          Customers
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field v-model="customerSearchTerm" @change="searchCustomer" :loading="searchResultsLoader" label="Search Customers" outlined></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card outlined :key="i" v-for="(customer, i) in customerSearchResults" class="d-flex flex-row align-center">
            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
              <h3>{{customer.name}}</h3>
              <p v-if="customer.email">{{customer.email}}</p>
              <p v-if="customer.phone">{{customer.phone}}</p>
            </span>
            <v-btn @click="setCustomer(customer)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomersDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="usersDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Users
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field v-model="userSearchTerm" @change="searchUser" :loading="searchResultsLoader" label="Search Users" outlined></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card :key="i" v-for="(user, i) in userSearchResults" class="d-flex flex-row align-center">
            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
              <h3>{{user.firstName}} {{user.lastName}}</h3>
              <p v-if="user.email">{{user.email}}</p>
              <p v-if="user.phone">{{user.phone}}</p>
            </span>
            <v-btn @click="setUser(user)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeUsersDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="addressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Customer Addresses
        </v-card-title>
        <v-card-text v-if="selectedCustomer && selectedCustomer.addresses && selectedCustomer.addresses.length>0">
          <v-card :key="i" v-for="(addr, i) in selectedCustomer.addresses" class="d-flex flex-row pa-2 justify-space-between mb-2" outlined>
            <span class="d-flex flex-column text-left">
              <h3 v-if="addr.name">{{addr.name}}</h3>
              <span v-if="addr.line1"><b>Address: </b>{{addr.line1}}{{addr.line2?", "+addr.line2:""}}</span>
              <span v-if="addr.city"><b>Town/City: </b>{{addr.city}}</span>
              <span v-if="addr.country"><b>Country: </b>{{addr.country}}</span>
            </span>
            <v-btn @click="setAddress(addr)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-text v-else>
          <span>This customer has no addresses.</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeAddressDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="saveCustomAddressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Save Custom Address
        </v-card-title>
        <v-card-text>
          <v-text-field type="text" dense outlined label="Name of Address" v-model="customAddressName"/>
          <div class="d-flex flex-column">
            <span v-if="invoice.deliveryInfo.address.line1">{{invoice.deliveryInfo.address.line1}}<span v-if="invoice.deliveryInfo.address.line2">, {{invoice.deliveryInfo.address.line2}}</span></span>
            <span v-if="invoice.deliveryInfo.address.city">{{invoice.deliveryInfo.address.city}}<span v-if="invoice.deliveryInfo.address.country">, {{invoice.deliveryInfo.address.country}}</span></span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomAddressDialog">
            Cancel
          </v-btn>
          <v-btn class="success" text @click="saveCustomAddress">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="changeUnitPriceTypeDialog.isOpen" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Change Unit Price Type for Product #{{changeUnitPriceTypeDialog.product?changeUnitPriceTypeDialog.product.productId:'-'}}
        </v-card-title>
        <v-card-text>          
          <v-radio-group v-model="changeUnitPriceTypeDialog.selectedVal">
            <v-radio
              :label="'Regular Price '+ this.formatPrice(this.changeUnitPriceTypeDialog.currentReg)"
              :value="0"
            ></v-radio>
            <v-radio
              v-if="this.changeUnitPriceTypeDialog.currentSale&&this.changeUnitPriceTypeDialog.currentSale>0"
              :label="'Sale Price '+ this.formatPrice(this.changeUnitPriceTypeDialog.currentSale)"
              :value="1"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeChangeUnitPriceType">
            Cancel
          </v-btn>
          <v-btn class="success" text @click="saveChangeUnitPriceType">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createDeliveryDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New Delivery
        </v-card-title>
        <v-card-actions>
          <v-btn small text color="error" @click="cancelCreateDelivery()">
            Cancel
          </v-btn>
          <v-btn small color="success" @click="createDeliveryB">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--    <v-dialog scrollable v-model="creditNotesDialog" width="500">-->
<!--      <v-card>-->
<!--        <v-card-title class="text-h5">-->
<!--          Credit Notes-->
<!--        </v-card-title>-->
<!--        <v-card-text>-->
<!--          <v-card :key="i" v-for="(note, i) in selectedCustomer.CreditNotes" class="d-flex flex-row align-center">-->
<!--            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">-->
<!--              <h3>Credit Note #{{note.id}}</h3>-->
<!--              <p>Amount: {{formatPrice(note.amount)}}</p>-->
<!--            </span>-->
<!--            <v-btn @click="addCreditNote(note)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>-->
<!--          </v-card>-->
<!--        </v-card-text>-->
<!--        <v-card-actions>-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn class="error" text @click="closeCreditNotesDialog">-->
<!--            Cancel-->
<!--          </v-btn>-->
<!--        </v-card-actions>-->
<!--      </v-card>-->
<!--    </v-dialog>-->

    <v-dialog
      v-model="printDialog.isOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Print
        </v-card-title>
        <v-card-text>
          <h3 style="margin: 0px;">Select item to print</h3>
          <v-radio-group v-model="printDialog.jobType" @change="filterPrinters" row mandatory>
            <v-radio
              label="Invoice Only"
              value="invoice"
            ></v-radio>
            <v-radio
              label="Delivery Note Only"
              value="deliveryNote"
            ></v-radio>
            <v-radio
              label="Job Ticket Only"
              value="jobTicket"
            ></v-radio>
            <v-radio
              label="Full Order Print"
              value="al"
              selected
            ></v-radio>
          </v-radio-group>
          <v-text-field v-if="printDialog.jobType=='deliveryNote'" style="margin-top: 10px;" dense v-model="printDialog.deliveryNote" type="number" label="Delivery Note To Print" outlined></v-text-field>
          <hr>
          <v-select
            class="mt-2"
            :items="filteredPrinters"
            item-text="name"
            item-value="id"
            label="Select a Printer"
            v-model="printDialog.printer"
            outlined
          ></v-select>
          <v-text-field style="margin-top: 10px;" dense v-model="printDialog.quantity" type="number" default="1" label="Quantity" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintDialog()">
            Cancel
          </v-btn>
          <authorizer 
            v-bind:buttontext="'Print'" 
            v-bind:small="true" 
            v-on:response="attemptPrint($event)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="productScanDialog.isOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Product Scanned
        </v-card-title>
        <v-card-text>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="productScanDialog.loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
          <!-- <p>{{this.productScanDialog.item}}</p> -->
          <span v-if="this.productScanDialog.item" class="d-flex flex-column justify-start" style="text-align: left;">
            <h3>{{this.productScanDialog.item.name}}</h3>
            <b style="font-size:12px;" v-if="this.productScanDialog.item.Brand" >Brand: {{this.productScanDialog.item.Brand.name}}</b>
            <span style="font-size:12px;">SKU: {{this.productScanDialog.item.sku?this.productScanDialog.item.sku:'-'}} | ID: PL-{{this.productScanDialog.item.id}}</span>
            <span>Reg: ${{this.productScanDialog.item.regularPrice?this.productScanDialog.item.regularPrice:'-'}} | Sale: ${{this.productScanDialog.item.salePrice?this.productScanDialog.item.salePrice:'-'}}</span>
            <span><b>Qty: {{this.productScanDialog.item.ProductLocationJoins&&this.productScanDialog.item.ProductLocationJoins.length>0?this.productScanDialog.item.ProductLocationJoins.reduce((acc,x)=>acc+parseInt(x.available),0):0}}</b></span>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeProductScanDialog()">
            Close
          </v-btn>
          <v-btn :disabled="this.modifyDisabledCriteria" text color="success" @click="()=>{addProductToInvoice(productScanDialog.item); closeProductScanDialog()}">Add To Order</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
#OrderLineItemsTable th, #OrderLineItemsTable td{
  text-align: center;
}
</style>
<script>
  import axios from 'axios';
  // import moment from 'moment';
  import scanSystem from '../../plugins/scanSystem'
  import _ from 'lodash'
  import {mapGetters, mapMutations} from 'vuex'
  import authorizer from '../../components/authorizer.vue'
  export default {
    components: {
      authorizer,
    },
    data () {
      return {
        scanSystem: scanSystem,
        ctx :  null,
        loader: false,
        initialLoader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        discountTypes: [
          {name: "%", value: 0},
          {name: "$", value: 1}
        ],

        invoice: {paymentType: 'full', deliveryInfo: {address: {}}, metadata: {grandTotal:0}, discount: 0},
        // customers: [],
        customerDisabledCriteria: false,
        productSearchResults: [],
        productSearchTerm: '',
        customerSearchTerm: '',
        userSearchTerm: '',
        noSearchResults: false,
        taxRate: 0,
        saveInvoiceLoader: false,

        addressDialog: false,
        saveCustomAddressDialog: false,
        customAddressName: "",

        customersDialog: false,
        customerValue: "",
        customerSearchResults: [],
        selectedCustomer: '',
        customerLoading: false,

        usersDialog: false,
        userSearchResults: [],
        selectedUser: '',

        createCustomerDialog: false,
        newCustomer: {addresses: []},
        nameRules: [
          v => !!v || 'Name is required'
        ],
        productScanDialog:{
          isOpen:false,
          loader: false,
          item: "",
        },
        existingData: [],
        listeningForBarcodeScan: false,
        productSearchBarcode: '',
        barcodeScanResult: {},
        discountAuth: '',
        ourAuthRequest: false,
        authorizerState: false,
        searchResultsLoader: false,
        loadingSearchProduct: false,
        numUpdates: 0,
        hidePaymentTypeFull: false,
        creditNotesDialog: false,
        printDialog: {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: '',
        },
        printPreviewDialog: {
          isOpen: false,
          data: '',
        },

        changeUnitPriceTypeDialog: {
          isOpen: false,
          product: null,
          currentReg: null,
          currentSale: null,
          selectedVal: null
        },
        singular: "Order",
        singularLower: "order",
        plural: "Orders",
        pluralLower: "orders",
        showDeliveryInfo: true,
        deliverySchedule: {
          tableHeaders:[
            {text: 'DN#', align: 'start', value: 'id'},
            { text: 'Delivery Date', value: 'dueDate' },
            { text: 'Units', value: 'numUnits' },
            { text: 'Status', value: 'status' },
          ],
          data: [],
        },
        paymentTable: {
          headers: [
            {text: 'Date', align: 'start', value: 'createdAt'},
            { text: 'Amount', value: 'amount' },
          ],
          items: [],
        },
        csr: null,
        syncStatus: 1, // 0 = updating; 1 = updateSuccess; 2 = updateFail
        printers: [],
        filteredPrinters: [],
        documentTypes: [],
        createDeliveryDialog: {
          isOpen: false
        },
        addPaymentDialog: {
          isOpen: false,
          selectedType: '',
          creditNotesAvailable: [],
          paymentAmount: null,
          chequeNumber: null,
          creditNotes: [],
          cnMessage: null,
        },
        viewPaymentDialog: {
          isOpen: false,
          paymentToDisplay: {},
        },
        createReturnDialog: {
          isOpen: false,
        },
      }
    },
    created(){
      this.debouncedUpdateInvoice = _.debounce(this.updateInvoice, 500)
    },
    async mounted(){
      try{
        this.initialLoader = true;
        let res = await axios.get(`${this.getEndpoint}/api/orders/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error
        if(res.data.data.Customer && res.data.data.Customer.CreditNotes){
          this.addPaymentDialog.creditNotesAvailable = res.data.data.Customer.CreditNotes
        //{id: 1, type: 0, status: 0, amount: 200, name: 'CN-1 | Normal | Valid | $200'},
          this.addPaymentDialog.creditNotesAvailable = this.addPaymentDialog.creditNotesAvailable.map(x=>{
            return {
              id: x.id,
              status: x.status,
              amount: x.amount,
              expiresAt: x.expiresAt,
              name: `CN-${x.id} -- Value: $${x.amount} -- Expires At: ${this.formatDate(x.expiresAt)} `
            }
          })
        }
        let userPrinters = this.getUser.metadata.printers
        let printers = await axios.get(`${this.getEndpoint}/api/devices/`)
        if(!printers.data.error){
          this.documentTypes = printers.data.data.documentTypes
          let printerDeviceTypeId = (printers.data.data.deviceTypes.filter(x=>x.name.toLowerCase()==='printer')[0]).id //looks at the deviceType object from the response to determine the id of the printers device type
          this.printers = printers.data.data.devices.filter(x=>x.deviceType===printerDeviceTypeId)
          this.printers.forEach(printer=>{
            if(userPrinters && userPrinters.default && userPrinters.default.length>0){
              for(let y in userPrinters.default){
                if(printer.data.jobTypes.includes(y)){
                  if(printer.id==userPrinters.default[y]){
                    printer['isDefault'] = true
                    printer.name = printer.name + ` (Default ${y} printer)`
                  }else printer['isDefault'] = false
                }
              }
            }
          })
          this.filteredPrinters = this.printers
          //invoice has its own scansense
          window.addEventListener('keypress', (e)=>{
            console.log(e)
            if(this.scanBus.locked && this.scanBus.type=="order"){
              this.cancelClearScanStringTimeout()
              this.clearScanStringTimeout()
              if(this.keyShortcutMode){
                this.cancelClearScanStringTimeout()
                if(e.key==='/'){
                  this.endShortcut()
                }else{
                  if(e.key==='Enter'){
                    console.log(this.scanString)
                    this.handleShortcut(this.scanString)
                  }else{
                    if(!this.scanString) this.scanString = ""
                    this.scanString = this.scanString + e.key
                  }
                }
              }else{
                if(e.key==='/'){
                  if(!this.scanString){
                    this.cancelClearScanStringTimeout()
                    this.keyShortcutMode = true
                  }else{
                    this.scanString = this.scanString + e.key
                  }
                }else{
                  if(e.key==='Enter'){
                    console.log("Enter pressed")  
                    let validatedType = scanSystem.validateType(this.scanString)
                    if(validatedType){
                      // this.setScanBusType(validatedType)
                      this.scanInProgress = true
                      this.handleScan(validatedType)
                      this.clearScanString
                    }else{
                      this.clearScanString
                    }
                  }else{
                    this.scanInProgress = false
                    if(!this.scanString) this.scanString = ""
                    this.scanString = this.scanString + e.key
                  }
                }
              }
            }
          })
        }else{
          console.log("Error loading printers")
        }
        if(!res.data.data){
          this.snack("Invoice could not be found.")
          await this.$router.go(-1);
        }

        if(!res.data.data.discountAmount) res.data.data.discountAmount = 0
        if(!res.data.data.OrderLineItems) res.data.data.OrderLineItems = []
        if(!res.data.data.metadata) res.data.data.metadata = {creditNotes:[], creditNotesTotal:0, grandTotal:0}
        if(!res.data.data.metadata.grandTotal) res.data.data.metadata.grandTotal = 0

        if(!res.data.data.deliveryInfo) res.data.data.deliveryInfo = {address: {}}

        this.invoice = res.data.data

        if(!this.invoice.metadata.paymentMethod) this.invoice.metadata.paymentMethod = 1

        if(!this.invoice.metadata)
          this.invoice.metadata = {grandTotal:0}
        if(!this.invoice.metadata.grandTotal) this.invoice.metadata.grandTotal = 0

        if(this.invoice.status!==0) this.showDeliveryInfo = false;
        if(!this.invoice.deliveryInfo || !this.invoice.deliveryInfo.cost) this.invoice.deliveryInfo.cost = 0
        let deliverySchedule = await axios.get(`${this.getEndpoint}/api/deliveries/byOrder/${this.$route.params.id}`)
        if(deliverySchedule.data.error) throw deliverySchedule.data.error

        this.deliverySchedule.data = deliverySchedule.data.data
        if(this.invoice.createdBy){
          let csr = await axios.get(`${this.getEndpoint}/api/users/forOrder/${this.invoice.createdBy}`)
          if(csr.data.error) throw csr.data.error
          if(csr.data.data)
            this.csr = csr.data.data
        }

        this.invoice.subtotal = 0
        this.updateTotals()
        if(this.invoice.customerId){
          let customer = await axios.get(`${this.getEndpoint}/api/customers/${this.invoice.customerId}`)
          if(customer.data.error) throw customer.data.error
          this.selectedCustomer = customer.data.data
          this.checkCustomAddress();
          // this.selectedCustomer.CustomerAdvanceAccounts = this.selectedCustomer.CustomerAdvanceAccounts.filter(x=>!x.closed)
        }
        if(this.invoice.OrderLineItems && this.invoice.OrderLineItems.length>0){
          this.invoice.OrderLineItems.forEach(item => {
            if(!item.metadata.commission){
              item.metadata.commission = {
                type: 0, // 0 is percent 1 is dollar value
                value: this.csr.metadata.commission
              };
            }
            if(item.metadata.commission.type===null){
              item.metadata.commission.type=0;
            }
          });
        }
        if(this.invoice.Transactions){
          this.paymentTable.items = this.invoice.Transactions
        }
        // if(this.getItemToAddToNewInvoice.id){
        //   let res = await axios.get(`${this.getEndpoint}/api/products/${this.getItemToAddToNewInvoice.id}`);
        //   let p = res.data.data.data
        //   p.quantityToAdd = this.getItemToAddToNewInvoice.quantity
        //   this.addProductToInvoice(p)
        //   this.clearNewInvoiceItem()
        // }
        // if(this.getItemToAddToExistingInvoice.id && this.getItemToAddToExistingInvoice.invoiceId == this.$route.params.id){
        //   if((this.invoice.orderItems.find(x=>x.id==this.getItemToAddToExistingInvoice.id))==undefined){
        //     let res = await axios.get(`${this.getEndpoint}/api/products/${this.getItemToAddToExistingInvoice.id}`);
        //     let p = res.data.data.data
        //     p.quantityToAdd = this.getItemToAddToExistingInvoice.quantity
        //     this.addProductToInvoice(p)
        //     this.clearExistingInvoiceItem()
        //   }else{
        //     this.snack("Product is already in the order")
        //     this.clearExistingInvoiceItem()
        //   }
        // }

        //contect for signature pad
        // this.ctx  = this.$el.getContext('2d')
        // this.ctx.strokeStyle  =  'black'
        // this.ctx.lineWidth  =  2

        console.log(this.invoice)
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      }
      finally {
        this.initialLoader = false;
      }
    },
    // async created(){
    //   try {

    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    computed: {
      ...mapGetters([
        'getId',
        'getEndpoint',
        'lookupUsername',
        'isAllowed',
        'getUser',
        'paymentMethods',
        'paymentTypes',
        'lookupPaymentMethods',
        'lookupPaymentType',
        'scanBus',
      ]),
      addPaymentDialogDisabledCriteria(){
        //returning true disables the button
        if(!this.addPaymentDialog.selectedType) return true
        if(!this.addPaymentDialog.paymentAmount) return true
        if(this.addPaymentDialog.paymentAmount<1) return true
        if(this.addPaymentDialog.paymentAmount>Math.abs(this.orderBalance)) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Cheque" && !this.addPaymentDialog.chequeNumber) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Credit Note" && this.addPaymentDialog.creditNotes.length==0) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Credit Note"){
          let y = this.addPaymentDialog.creditNotesAvailable.filter(x=>this.addPaymentDialog.creditNotes.includes(x.id)).reduce((acc,x)=>acc+parseFloat(x.amount),0)
          if(this.addPaymentDialog.paymentAmount > y) return true
        }
        return false
      },
      orderBalance(){
        return this.paymentTable.items&&this.paymentTable.items.length>0?parseFloat(this.paymentTable.items.reduce((acc,x)=>acc+parseFloat(x.amount),0)-parseFloat(this.invoice.metadata.grandTotal)):(0-parseFloat(this.invoice.metadata.grandTotal))
      },
      discountOk: function(){
        if(!this.discountApplied && this.invoice.discountAmount) return false
        return true
      },
      discountApplied(){
        if(this.invoice.discountAmount && this.invoice.discountAmount > 0)
          return true
        return false
      },
      modifyDisabledCriteria(){
        return parseInt(this.invoice.status) !== 0
      }
    },
    methods: {
      ...mapMutations([
        'updateWorkingId',
        'deleteWorkingId',
        'setScanBusData',
        'setScanBusType',
        'resetScanBus',
        'openSIDAuthorizer',
        'closeSIDAuthorizer',
        'clearNewInvoiceItem',
        'clearExistingInvoiceItem',
      ]),
      createDeliveryA(){
        this.createDeliveryDialog.isOpen = true
      },
      async createDeliveryB(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/deliveries`, {orderId: this.$route.params.id, createdBy: this.getId})
          if(res.data.error) throw res.data.error
          this.createDeliveryDialog.isOpen = false
          await this.$router.push({ path: `/deliveries/view/${res.data.data.id}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      cancelCreateDelivery(){
        this.createDeliveryDialog.isOpen = false
      },
      filterPrinters(){
        this.filteredPrinters = this.printers.filter(x=>x.data.jobTypes.includes(this.printDialog.jobType)).sort((a,b)=>b.isDefault-a.isDefault)
        this.printDialog.printer = this.filteredPrinters.filter(x=>x.id==this.getUser.metadata.printers.default[this.printDialog.jobType])[0]
      },
      removeCustomerFromInvoice(){
        if(this.invoice.Customer) delete this.invoice.Customer
        this.invoice.customerId = null
        this.selectedCustomer = null;
        this.customerSearchTerm = ''
        this.checkCustomAddress();
        this.invoice.deliveryInfo.address = {}
        this.updateInvoice()
      },
      customerSelected(){
        console.log(this.invoice)
      },
      async searchProduct(){
        try {
          if(this.productSearchTerm){
            this.loadingSearchProduct = true;
            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAll?val=${encodeURIComponent(this.productSearchTerm)}`);
            if(res.data.error) throw res.data.error
            console.log(res.data.data)
            this.productSearchResults = res.data.data
            if(this.productSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally {
          this.loadingSearchProduct = false;
        }
      },
      clearProductSearchResults(){
        this.productSearchResults = []
        this.productSearchTerm = ""
      },
      watchInput(){
        if(this.productSearchTerm.length == 0) this.noSearchResults = false
      },
      async fetchCsr(){
        try {
          if(!this.invoice.authorizations.seal.authBy) throw "No CSR Associated with Order."
          let csr = await axios.get(`${this.getEndpoint}/api/users/forOrder/${this.invoice.authorizations.seal.authBy}`)
          if(csr.data.error) throw csr.data.error
          this.csr = csr.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      async removeProduct(e){
        try {
          this.loader = true
          this.syncStatus = 0
          let res = await axios.delete(`${this.getEndpoint}/api/orders/lineItem/${e.id}`)
          if(res.data.error) throw res.data.error
          let i = (this.invoice.OrderLineItems.findIndex(x=>x.id===e.id))
          this.invoice.OrderLineItems.splice(i,1)
          this.resetDiscount()
          this.updateTotals()
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.loader = false
          this.syncStatus = 1
        }
      },
      async addProductToInvoice(p){
        try {
           // format the product here
          if(this.invoice.OrderLineItems.find(x=>x.productId==p.id)==undefined){
            this.syncStatus = 0
            this.loader = true
            let tempLineItem = {
              orderId: this.invoice.id,
              productId: p.id,
              productName: p.name,
              sku: p.sku,
              quantity: 1,
              unitPrice: p.regularPrice,
              discountValue: 0,
              discountType: 0,
              metadata: {
                serials: [],
                lineTotal: p.quantityToAdd*(p.regularPrice),
                commission: {type: 0, value: 0},
                unitPriceType: 0 // reg = 0; sale = 1
              }
            }
            let res = await axios.post(`${this.getEndpoint}/api/orders/lineItem/${this.$route.params.id}`, tempLineItem)
            if(res.data.error) throw res.data.error
            console.log(res.data.data)
            this.invoice.OrderLineItems.push(res.data.data)
            this.resetDiscount()
            this.updateTotals()
            this.snack('✅ Item Added')
          }else{
            this.snack("Item already in invoice.")
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        } finally {
          this.syncStatus = 1
          this.loader = false
        }
      },
      checkAndUpdateLines(){
        this.invoice.OrderLineItems.forEach(p=>{
          p.discountValue = parseFloat(p.discountValue)
          if(!p.quantity || p.quantity < 1) p.quantity = 0
          if(!p.discountValue || p.discountValue < 0) p.discountValue = 0
          if(p.discountType==0 && p.discountValue > 20){
            p.discountValue = 20
            this.snack("Discount Value cannot exceed 20%.")
          }
          // if(p.metadata.commission.value < 0) p.metadata.commission.value = 0
          // if(parseInt(p.metadata.commission.value) > 100) p.metadata.commission.value = 0

          let discountVal = 0;
          if(p.discountType===1) discountVal = p.discountValue
          else discountVal = (p.unitPrice*(p.discountValue/100))
          p.metadata.lineTotal = p.quantity*(p.unitPrice-discountVal)
        })
      },
      async updateLineItem(item){
        try {
          this.syncStatus = 0
          this.loader = true
          if(item.quantity < 0){
            item.quantity = 0
            throw "Quantity cannot be less than 0."
          }
          let res = await axios.put(`${this.getEndpoint}/api/orders/lineItem/${item.id}`, item)
          if(res.data.error) throw res.data.error
          if(!res.data.success) this.snack("Insufficient quantity in stock.")
          let ix = this.invoice.OrderLineItems.findIndex(x=>x.id==res.data.data.id)
          ix==-1?null:this.invoice.OrderLineItems.splice(ix, 1, res.data.data)
          this.resetDiscount()
          this.updateTotals()
          this.$forceUpdate()
        } catch (error) {
          console.log(error)
          this.snack(error.msg|| error)
        } finally {
          this.syncStatus = 1
          this.loader = false
        }
      },
      updateLineDiscount(p){
        console.log("Update Line Discount")
        if(p.discountValue < 0) p.discountValue = 0
        if(p.discountType==0 && p.discountValue > 20) p.discountValue = 0
        this.updateLineTotal(p)
      },
      updateLineCommission(p){
        console.log("Update Line Commission")
        if(p.metadata.commission.value < 0) p.metadata.commission.value = 0
        if(parseInt(p.metadata.commission.value) > 100) p.metadata.commission.value = 0
        console.log("He")
        this.updateLineTotal(p)
      },
      updateTotals(){
        this.checkAndUpdateLines()
        this.invoice.deliveryInfo.cost = Math.abs(parseFloat(this.invoice.deliveryInfo.cost))
        this.invoice.subtotal = this.invoice.OrderLineItems.reduce((a,x)=>a+x.metadata.lineTotal,0)
        this.invoice.metadata.grandTotal = (this.invoice.subtotal - this.invoice.discountAmount) + this.invoice.deliveryInfo.cost
        this.$forceUpdate()
      },
      formatPrice(value){
        if(value===null) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      resetExisting(){
        this.existingData = [];
      },
      async searchUser(){
        try {
          if(this.userSearchTerm){
            this.searchResultsLoader = true
            let res = await axios.get(`${this.getEndpoint}/api/users/search?val=${encodeURIComponent(this.userSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.userSearchResults = res.data.data
            if(this.userSearchResults && this.userSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally{
          this.searchResultsLoader = false
        }
      },
      async searchCustomer(){
        try {
          if(this.customerSearchTerm){
            this.searchResultsLoader = true
            let res = await axios.get(`${this.getEndpoint}/api/customers/search?val=${encodeURIComponent(this.customerSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.customerSearchResults = res.data.data
            if(this.customerSearchResults && this.customerSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally{
          this.searchResultsLoader = false
        }
      },
      async updateInvoice(){
        try {
          if(this.invoice&&this.invoice.deliveryInfo&&!this.invoice.deliveryInfo.cost) this.invoice.deliveryInfo.cost = 0
          this.syncStatus = 0
          this.updateTotals()
          if(this.modifyDisabledCriteria) return // very important it must be at the top
          if(this.initialLoader) return 
          if(this.invoice.status > 0){
            throw "❌ Order already Sealed."
          }
          if(!this.invoice.discountAmount) this.invoice.discountAmount = 0
          let res = await axios.put(`${this.getEndpoint}/api/orders/${this.$route.params.id}`, this.invoice)
          if(res.data.error) throw res.data.error
          console.log(res.data)
          this.draftWasSaved = true
          this.updateTotals()
          this.syncStatus = 1
        } catch (error) {
          this.syncStatus = 2
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.loader = false
        }
      },
      resetDiscount(){
        this.invoice.discountAmount = 0
      },
      updateOrderDiscount(){
        if(this.invoice.discountAmount < this.invoice.subtotal*0.2){
          this.updateInvoice()
        }else{
          this.snack("The Discount was not applied. Reason: Discount value > 20%.")
        }
      },
      closeUsersDialog(){
        this.usersDialog = false;
        this.userSearchTerm = ''
        this.userSearchResults = [];
        this.$forceUpdate()
      },
      async setUser(user){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/users/${user.id}`)
          if(res.data.error) throw res.data.error
          this.invoice.createdBy = user.id;
          this.csr = res.data.data
          await this.updateInvoice();
          this.closeUsersDialog();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closeCustomersDialog(){
        this.customersDialog = false;
        this.customerSearchTerm = ''
        this.customerSearchResults = [];
        this.$forceUpdate()
      },
      async setCustomer(customer){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/customers/${customer.id}`)
          if(res.data.error) throw res.data.error
          this.invoice.customerId = customer.id;
          this.selectedCustomer = res.data.data
          this.closeCustomersDialog();
          this.invoice.deliveryInfo.address = {}
          this.checkCustomAddress();
          this.updateInvoice()
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closeCreateCustomer(){
        this.createCustomerDialog = false;
        this.newCustomer = {addresses: []};
        this.customerLoading = false;
        this.existingData = [];
      },
      async createCustomer(){
        try{
          this.customerLoading = true;
          let res = await axios.post(`${this.getEndpoint}/api/customers/createAdHoc`, this.newCustomer)
          if(res.data.error) throw res.data.error
          console.log(res.data.data)
          this.snack("Customer Created.")
          this.invoice.customerId = res.data.data.id
          this.selectedCustomer = res.data.data
          this.closeCreateCustomer();
        }
        catch (error){
          console.error(error)
          this.snack(error.msg || error, "error")
        }
        finally {
          this.customerLoading = false;
        }
      },
      setAddress(addr){
        this.invoice.deliveryInfo.address = {line1: addr.line1, line2: addr.line2, city: addr.city, country: addr.country, name: addr.name};
        this.invoice.deliveryInfo.customAddress = false;
        this.updateInvoice()
        this.closeAddressDialog();
      },
      closeAddressDialog(){
        this.addressDialog = false;
      },
      async saveCustomAddress(){
        try{
          if(this.customAddressName.length===0) throw "A name is required."
          let addr = {...this.invoice.deliveryInfo.address};
          addr.name = this.customAddressName;
          let res = await axios.put(`${this.getEndpoint}/api/customers/addAddress/${this.selectedCustomer.id}`, addr)
          if(res.data.error) throw res.data.error
          this.snack("Address added successfully!");
          this.invoice.deliveryInfo.customAddress = false;
          this.selectedCustomer.addresses.push({line1: addr.line1, line2: addr.line2, city: addr.city, country: addr.country, name: addr.name});
          this.closeCustomAddressDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      checkCustomAddress(){
        let val = true;
        if(this.selectedCustomer && this.selectedCustomer.addresses){
          for(let addr of this.selectedCustomer.addresses){
            if((this.invoice.deliveryInfo.address.line1===addr.line1 &&
                    this.invoice.deliveryInfo.address.line2===addr.line2 &&
                    this.invoice.deliveryInfo.address.city===addr.city &&
                    this.invoice.deliveryInfo.address.country===addr.country) ||
                (this.invoice.deliveryInfo.address.line1==="" &&
                    this.invoice.deliveryInfo.address.line2==="" &&
                    this.invoice.deliveryInfo.address.city==="" &&
                    this.invoice.deliveryInfo.address.country==="")){
              val = false;
            }
          }
        }
        else{
          val = false;
        }
        this.invoice.deliveryInfo.customAddress = val;
        this.$forceUpdate();
      },
      closeCustomAddressDialog(){
        this.customAddressName = "";
        this.saveCustomAddressDialog = false;
      },
      listenForBarcodes(){
        this.lockGlobalQueryBc()
        console.log("Listening for Barcode Scan")
        this.listeningForBarcodeScan = true
        this.$refs.barcodeTerm.focus()
      },
      cancelListenForBarcodes(){
        this.unlockGlobalQueryBc()
        this.listeningForBarcodeScan = false
        this.$refs.barcodeTerm.blur()
        this.productSearchBarcode = ''
      },
      barcodeSearchProduct(){
        this.queryDialogSearch()
      },
      async queryDialogSearch(){
        try {
          let pId = (this.productSearchBarcode.slice(0,5)).replace(/^0+/, '');
          this.queryDialogText = `ID Scanned: ${this.fromBase62(pId)}`
          let res = await axios.get(`${this.getEndpoint}/api/products/searchByIdWithVariations/${this.fromBase62(pId)}`);
          if(res.data.error) throw res.data.error

          if(res.data.data===null){
            this.productSearchBarcode = ''
            throw "Invalid barcode value. Try again.";

          }else{
            this.barcodeScanResult = res.data.data
            this.cancelListenForBarcodes()

            this.addProduct(this.barcodeScanResult)
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      toBase62(n) {
        if (n === 0) {
          return '0';
        }
        var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        while (n > 0) {
          result = digits[n % digits.length] + result;
          n = parseInt(n / digits.length, 10);
        }

        return result;
      },
      fromBase62(s) {
        var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = 0;
        for (var i=0 ; i<s.length ; i++) {
          var p = digits.indexOf(s[i]);
          if (p < 0) {
            return NaN;
          }
          result += p * Math.pow(digits.length, s.length - i - 1);
        }
        return result;
      },
      async authAttempt(credentials, type){
        try {
            if(!credentials) throw "No Credentials Supplied"
            let x = {}
            console.log("Here")
            switch(type){
              case "seal":
                x = await axios.post(`${this.getEndpoint}/api/orders/changeStatus/sealed/${this.$route.params.id}`, credentials)
                if(x.data.error) throw x.data.error
                this.invoice.status = 1
                this.addPaymentDialog.isOpen = true;
                break
              case "void":
                x = await axios.post(`${this.getEndpoint}/api/orders/changeStatus/voided/${this.$route.params.id}`, credentials)
                if(x.data.error) throw x.data.error
                this.invoice.status = -1
                break
              case "discount":
                x = await axios.post(`${this.getEndpoint}/api/orders/authDiscount/${this.$route.params.id}`, credentials)
                if(x.data.error) throw x.data.error
                this.invoice.discountBy = x.data.data.discountBy
                this.$forceUpdate()
                break
              default:
                throw "No Type Supplied to Handler"
            }
            this.snack("✅ Success.")
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      openPrintDialog(){
        this.printDialog.isOpen = true
      },
      async printPreview(){
        try {
          let x = await axios.post(`${this.getEndpoint}/api/print/previewInvoice/${this.$route.params.id}`)
          if(x.data.error) throw x.data.error
          this.printPreviewDialog.data = x.data
          this.printPreviewDialog.isOpen = true
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closePrintPreviewDialog(){
          this.printPreviewDialog.data = ''
          this.printPreviewDialog.isOpen = false
      },
      closePrintDialog(){
        this.printDialog = {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: ''
        }
      },
      async attemptPrint(e){
        try {
            if(!e.pin&&!e.vsidToken) throw "Error Getting Credentials"
            e.metadata = this.printDialog
            let documentId = (this.printDialog.jobType === 'invoice'||this.printDialog.jobType === 'jobTicket'?this.$route.params.id:this.printDialog.deliveryNote)
            let res = await axios.post(`${this.getEndpoint}/api/print/${this.printDialog.jobType}/${documentId}`, e)
            if(res.data.error) throw res.data.error
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.closePrintDialog();
        }
      },
      invoiceOK: function(){
        let verbose = false;
        if(verbose) console.log(this.invoice);
        //payment type ok?
        if(this.invoice && !this.invoice.metadata.paymentMethod) return false
        // if(this.invoice && this.invoice.metadata.paymentMethod == 'onAccount'){
        //   if(this.invoice.metadata && !this.invoice.metadata.arDownpayment) return false
        // }
        if(verbose) console.log("payment ok.")
        // customer ok?
        if(!this.invoice.customerId) return false
        if(verbose) console.log("customer ok.")
        //products ok?
        if(this.invoice.OrderLineItems && this.invoice.OrderLineItems.length == 0) return false
        if(this.invoice.grandTotal == 0) return false
        if(verbose) console.log("products ok.")
        //discountOk?
        // if(!this.discountOk) return false
        // if(verbose) console.log("discount ok.")
        //delivery info ok?
        if(!this.invoice.deliveryInfo.deliveryType) return false
        if(this.invoice.deliveryInfo.deliveryType === 'delivery'){
          if(!this.invoice.deliveryInfo.address) return false
        }
        if(verbose) console.log("delivery ok.")
        return true
      },
      async addSerialToItem(item){
        try {
          let res = await axios.put(`${this.getEndpoint}/api/orders/addSerialToInvoiceItem/${this.$route.params.id}/${item.id}`, {serial: item.serialField})
          if(res.data.error) throw res.data.error

          console.log(res.data.data)
          this.snack("Serial Added")
          item.serials.push(res.data.data)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          item.serialField = ''
        }
      },
      updateLineItemDiscountType(i){
        let obj = {
          ...this.invoice.OrderLineItems[i],
          discountValue: 0,
          discountType: this.invoice.OrderLineItems[i].discountType===0?1:0
        }
        this.updateTotals()
        this.$set(this.invoice.OrderLineItems, i, obj)
        this.$forceUpdate();
      },
      updateLineItemCommissionType(i){
        let obj = {
          ...this.invoice.OrderLineItems[i],
          metadata: {
            ...this.invoice.OrderLineItems[i].metadata,
            commission: {
              ...this.invoice.OrderLineItems[i].commission,
              type:this.invoice.OrderLineItems[i].commission.type===0?1:0,
              value: 0
            }
          }
        }

        this.$set(this.invoice.OrderLineItems, i, obj)
        this.$forceUpdate();
      },
      // openCreditNotesDialog(){
      //   this.creditNotesDialog = true;
      // },
      // closeCreditNotesDialog(){
      //   this.creditNotesDialog = false;
      // },
      // addCreditNote(note){
      //   console.log(note);
      //   this.calcCreditNotesTotal()
      // },
      // removeCreditNote(i){
      //   this.invoice.metadata.creditNotes.splice(i, 1);
      //   this.calcCreditNotesTotal()
      // },
      // calcCreditNotesTotal(){
      //   if(this.invoice.metadata.creditNotes.length===0){
      //     this.invoice.metadata.creditNotesTotal = 0;
      //     return;
      //   }
      //   let total = 0;
      //   this.invoice.metadata.creditNotes.forEach(note => {
      //     total+=note.amount;
      //   });
      //
      //   this.invoice.metadata.creditNotesTotal = total;
      // },
      rowClick(row){
        this.$router.push({path: `/deliveries/view/${row.id}`})
      },
      parseDeliveryStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Delivered"
      },
      parseOrderStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
      },
      formatDate(d, type="short"){
        if(!d) return "None";
        if(type=="short")
          return (new Date(d)).toLocaleDateString('en-GB')
        if(type=="long")
          return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
      },
      applyDefaultCommissionToAll(){
        this.invoice.OrderLineItems.forEach(item=>{item.metadata.commission.type = 0; item.metadata.commission.value = this.csr.metadata.commission})
        this.snack("✅ Line commissions updated to CSR default of "+ this.csr.metadata.commission + "%.")
      },
      clearScanString(){
        this.scanString = ''
        this.scanInProgress = false
        console.log("🔁 Scan String Cleared")
      },
      clearScanStringTimeout(){
        this.timeout = setTimeout(this.clearScanString,500)
      },
      cancelClearScanStringTimeout(){
        clearTimeout(this.timeout)
      },
      async handleScan(type){
        try {
          let s = this.scanString.replace(/\\\/\/\\=/,'')
          let pId = (s.replace(`${type}-`, ''));
          let p;
          pId
          let prod = null
          switch(type){
            case 'INV':
              console.log("Invoice Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'VSID':
              // this.setScanBusData({username: this.lookupUsername(pId.split("-")[0]), uId: pId.split("-")[0]})
              // console.log(this.lookupUsername(pId.split("-")[0]))
              p = '/users/view/'+pId.split("-")[0]
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'JT':
              console.log("Job Ticket Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'DN':
              console.log("Delivery Note Scanned: ", s)
              p = `/deliveries/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'PL':
              console.log("Product Scanned: ", s) //this
              this.openProductScanDialog()
              prod = await this.lookupProduct((s.split('-')[1]), "ID")
              if(!prod) throw "Product Not In VIMS DB."
              this.productScanDialog.item = prod
              break
            case 'EXT':
              console.log("Invoice External Product Scanned: ", s)
              this.openProductScanDialog()
              prod = await this.lookupProduct(s, "SKU")
              if(!prod) throw "External Product Not In VIMS DB."
              this.productScanDialog.item = prod
              break
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.scanInProgress = false
        }
      },
      openProductScanDialog(){
        this.productScanDialog.isOpen = true
        this.productScanDialog.loader = true
      },
      closeProductScanDialog(){
        this.productScanDialog.isOpen = false,
        this.productScanDialog.loader = false
        this.productScanDialog.item = ''
      },
      async lookupProduct(s, type){
        try {
          let res;
          if(type === "SKU")
            res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
          if(type === "ID")
            res = await axios.get(`${this.getEndpoint}/api/products/forCSR/${s}`)
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw 'External barcode is not in VIMS DB.'
          console.log(res.data.data)
          return res.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.productScanDialog.loader = false
        }
      },
      openAddPayment(){
        this.addPaymentDialog.isOpen = true
      },
      closeAddPaymentDialog(){
        this.addPaymentDialog.isOpen = false
        this.addPaymentDialog.selectedType = null
        this.addPaymentDialog.paymentAmount = null
        this.addPaymentDialog.chequeNumber = null
        this.addPaymentDialog.creditNotes = []  
        this.addPaymentDialog.cnMessage = null
      },
      async addPayment(){
        try {
          let obj = {
            orderId: this.$route.params.id,
            amount: this.addPaymentDialog.paymentAmount,
            paymentType: this.addPaymentDialog.selectedType,
            metadata: {
              chequeNumber: this.addPaymentDialog.chequeNumber,
              creditNotes: this.addPaymentDialog.creditNotes
            }
          }
          console.log(obj)
          let res = await axios.post(`${this.getEndpoint}/api/orders/addPayment/${this.$route.params.id}`, obj)
          if(res.data.error) throw res.data.error
          this.snack("Order Added")
          this.closeAddPaymentDialog()
          this.snack("Payment Successfully Added.")
          this.paymentTable.items.push(res.data.data)
        } catch (error) {
          this.snack(error)
          console.log(error)
        }
      },
      paymentTableRowClick(e){
        this.viewPaymentDialog.paymentToDisplay = e
        this.viewPaymentDialog.isOpen = true
        console.log(this.viewPaymentDialog)
      },
      closeViewPaymentDialog(){
        this.viewPaymentDialog.isOpen = false
        this.viewPaymentDialog.paymentToDisplay = {}
      },
      runPaymentDialogValidations(){
        this.addPaymentDialog.cnMessage = null
        if(this.addPaymentDialog.selectedType&&this.addPaymentDialog.paymentAmount){
          console.log(this.lookupPaymentType(this.addPaymentDialog.selectedType))
          if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=='Credit Note'){
            if(this.addPaymentDialog.creditNotes.length>0){
              let y = this.addPaymentDialog.creditNotesAvailable.filter(x=>this.addPaymentDialog.creditNotes.includes(x.id)).reduce((acc,x)=>acc+parseFloat(x.amount),0)
              if(y > this.addPaymentDialog.paymentAmount){
                this.addPaymentDialog.cnMessage = "The Value of the selected CN exceeds the tendered amount. A new CN will be created with the Customer's Balance."
              }else if(this.addPaymentDialog.paymentAmount > y){
                this.addPaymentDialog.cnMessage = "The Value of the tendered exceeds the CN amounts."
              }else{
                this.addPaymentDialog.cnMessage = null
              }
            }
            else{
              this.addPaymentDialog.cnMessage = null  
            }
          }else {
            this.addPaymentDialog.cnMessage = null
            this.addPaymentDialog.creditNotes = []
          }
        }
      },
      async createNewReturn(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/`)
          if(res.data.error) throw res.data.error  
          this.createDialog.isOpen = false
          await this.$router.push({ path: `/${this.pluralLower}/create/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error, "error");
         this.createDialog.isOpen = false
        }
      },
      async openChangeUnitPriceType(p){
        try {
          if(this.modifyDisabledCriteria) return
          this.changeUnitPriceTypeDialog.product = p
          this.changeUnitPriceTypeDialog.isOpen = true
          let res = await axios.get(`${this.getEndpoint}/api/products/forCSR/${p.productId}`)
          if(res.data.error) throw res.data.error
          this.changeUnitPriceTypeDialog.currentReg = res.data.data.regularPrice
          this.changeUnitPriceTypeDialog.currentSale = res.data.data.salePrice
        } catch (error) {
         this.snack(error.msg || error)
         console.log(error) 
        }
      },
      closeChangeUnitPriceType(){
        this.changeUnitPriceTypeDialog.product = null
        this.changeUnitPriceTypeDialog.isOpen = false    
        this.changeUnitPriceTypeDialog.currentReg = null
        this.changeUnitPriceTypeDialog.currentSale = null
        this.changeUnitPriceTypeDialog.selectedVal = 0
      },
      async saveChangeUnitPriceType(){
        try {
          let res = await axios.put(`${this.getEndpoint}/api/orders/lineItemPriceType/${this.changeUnitPriceTypeDialog.product.id}`, {unitPriceType: this.changeUnitPriceTypeDialog.selectedVal})
          if(res.data.error) throw res.data.error
          console.log(res.data.data)
          this.changeUnitPriceTypeDialog.product.unitPrice = res.data.data.unitPrice
          this.changeUnitPriceTypeDialog.product.metadata.unitPriceType = this.changeUnitPriceTypeDialog.selectedVal
          this.updateTotals()
          // let id = this.invoice.OrderLineItems.findIndex(x=>x.id==this.changeUnitPriceTypeDialog.product.id)
          // this.invoice.OrderLineItems.splice(id, 1, this.changeUnitPriceTypeDialog.product)
          this.snack("Success")
          this.closeChangeUnitPriceType()
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error)
        }
      },
      checkDiscount(){
        let discountCap = 0
        if(this.isAllowed('order', 'discount100')) discountCap = 100
        if(this.isAllowed('order', 'discount15')) discountCap = 15
        if(this.invoice.discountAmount/this.invoice.subtotal*100>discountCap){
          this.snack("Discount amount too high. Resetting to 0.")
          this.invoice.discountAmount = 0
        }else
          this.updateInvoice()
      }
    }
  }
</script>